import { render, staticRenderFns } from "./NewWorkflowConfig.vue?vue&type=template&id=6c61b51a&scoped=true&"
import script from "./NewWorkflowConfig.vue?vue&type=script&lang=js&"
export * from "./NewWorkflowConfig.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c61b51a",
  null
  
)

export default component.exports